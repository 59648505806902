<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import axios from 'axios';

import vue2Dropzone from "vue2-dropzone";
import {saveAs} from 'file-saver';

import {
  required,
  //email,
  minLength,
  //sameAs,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Team registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, vueDropzone: vue2Dropzone },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title: "Team registration",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Teams",
          href: "/management/teams"
        },
        {
          text: "Team",
          active: true
        }
      ],

      load:                 0,
      coaches:              [],
      selectedPool:         "",
      selectedCoach:        "",
      server:               "",

      form: {
        id_coach:           "",
        nm_team:            "",
        url_photograph:     "",
      },
      dropzoneOptions: {
        url: window.serverPath+'dist/mvw/controllers/teams.php',
        thumbnailWidth: 100,
        maxFilesize: 2.5,
        headers: { "My-Awesome-Header": "header value" },
        multipleUpload: false,
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        init: function() {
          this.on("maxfilesexceeded", file => {
            this.removeFile(file);
            Swal.fire('Ooops!!!','<p><b>You can only upload a single template file.</p>','error');
          });
        }
      },
      submitted: false,
    };
  },
  validations: {
    form: {
      nm_team:            { required, minLength: minLength(2), maxLength: maxLength(100) },
      id_coach:           { required },
    }
  },
  watch:{
    validToken(newValue){
      if( newValue == false ){
        this.$bvModal.show('loginModal');
      }
    }
    /*
    load: function(){
      let app = this;
      if(this.load == 4 ){
        this.pools.forEach(function(pool){
          if(app.form.id_pool == pool.id_pool){
            app.selectedPool = pool;
          }
        });

        this.coaches.forEach(function(coach){
          if(app.form.id_coach == coach.id_coach){
            app.selectedCoach = coach;
          }
        });

        this.getScheduleByPoolAndCoachID();
      }
    }
    */
  },
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    this.server = window.serverPath+'dist';
    this.getCoaches();

    if( this.$route.params.id ){
      this.getTeamByID();
    }
  },
  methods: {
    async getTeamByID(){
      //let app = this;

      Swal.fire({
        title: "Loading team data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });
      
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/teams.php',
        {
          params: {
            request: 'getTeamByID',
            id:this.$route.params.id,
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          this.form.id_coach          = response.data.id_coach;
          this.form.nm_team           = response.data.nm_team;
          this.form.url_photograph    = response.data.url_photograph;

          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load team data! Please load this module again.", "error");
        }
      });
    },

    async getCoaches(){
      axios
      .get(
        window.serverPath+'dist/mvw/controllers/coaches.php',
        {
          params: {
            request: 'getCoaches',
            token: this.user.token,
          }
        }
      )
      .then(response => {
        if( response.status == 200 ){
          if(response.data == 'Invalid token'){
            Swal.close();
            this.validToken = false;
          }else{
            this.coaches = response.data;
            this.load++;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load coaches data! Please load this module again.", "error");
        }
      });
    },

    // eslint-disable-next-line no-unused-vars
    saveTeam(e) {
      let app = this;

      this.submitted        = true;
      // stop here if form is invalid
      this.$v.$touch();

      if( !this.$v.form.$error ){
        if( this.$route.params.id ){
          this.form.id_team = this.$route.params.id;
        }

        Swal.fire({
          title: "Saving the team data.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if(this.$refs.myVueDropzone.dropzone.files.length > 0 ){
          this.$refs.myVueDropzone.processQueue();
        }else{
          axios.post(window.serverPath+'dist/mvw/controllers/teams.php', {
            request: 'saveTeam',
            data: this.form,
            token: this.user.token,
          })
          .then(function (response) {
            let action = "";
            if( response.data === "OK" ){
              action = "saved";
            }
            if( response.data === "UPDATED" ){
              action = "updated";
            }

            Swal.fire("Good job!", "The team is correct "+action+"!", "success")
            .then(function(response){
              if(response.isConfirmed){
                app.$router.push({ name: 'teams' });
              }
            });
          })
          .catch(function (error) {
            Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
          });
        }
      }
    },

    sendingFile(file, xhr, formData){
      let app = this;
      
      file;
      xhr;
      formData;

      //Appending the request, file and data to the formData object to be send all in a single request.
      formData.append('request',  'saveTeam');
      formData.append('token',    this.user.token);
      formData.append('data',     JSON.stringify(app.form) );
    },

    uploadCompleted(response){
      let app = this;

      console.log(response);
      if(response.xhr.status == 200){
        Swal.close();
        Swal.fire("Good job!", "The team is correct saved!", "success")
        .then(function(response){
          if(response.isConfirmed){
            app.$router.push({ name: 'teams' });
          }
        });
      }else{
        Swal.close();
        Swal.fire("Ooops!", "There was an error when trying to save this team data. Please try again.", "error");
      }
    },

    downloadTicket(){
      //let app = this;
      axios
      .get(window.serverPath+'dist'+this.form.url_photograph, {responseType: 'blob'})
      .then(response => {
          saveAs(response.data);
      })
    },

    openTicket(){
      window.open(window.serverPath+'dist'+this.form.url_photograph);
    },

    consoleThis(){
      console.log(this.form.de_materials);
    },

    selectThisOption(id){
      this.form.de_materials.forEach(function(material){
        console.log(material);
        if(material == id){
          return "selected";
        }
      });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="saveTeam">
              <div class="row">
                <div class="col-sm-4">
                  <h4 class="card-title">Team Data</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="nm_team">Team name</label>
                        <input
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': submitted && $v.form.nm_team.$error }"
                          name="nm_team"
                          id="nm_team"
                          v-model="form.nm_team"
                          value=""
                        >
                        <div v-if="submitted && $v.form.nm_team.$error" class="invalid-feedback">
                          <span v-if="!$v.form.nm_team.required">Member name is required.</span>
                          <span v-if="!$v.form.nm_team.minLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                          <span v-if="!$v.form.nm_team.maxLength">This value length is invalid. It should be between 2 and 50 characters long.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <h4 class="card-title">Other team data</h4>
                  <div class="form-group">
                    <label for="id_coach">Coach</label>
                    <select
                      class="form-control"
                      name="id_coach"
                      id="id_coach"
                      v-model="form.id_coach"
                      :class="{ 'is-invalid': submitted && $v.form.id_coach.$error }"
                    >
                      <option value="">SELECT COACH</option>
                      <option v-for="(coach, index) in coaches" :key="index" :value="coach.id_coach">{{coach.nm_coach + " " + coach.nm_lastName}}</option>
                    </select>
                    <div v-if="submitted && $v.form.id_coach.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id_coach.required">Coach is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <h4 class="card-title">Upload member picture</h4>
                  <div class="form-group">
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 btn btn-sm btn-outline-dark" @click="downloadTicket">
                      Download photograph
                      <b-badge variant="dark" class="ml-1"><i class="fas fa-download"></i></b-badge>
                    </button>
                    <button v-if="form.url_photograph != ''" type="button" class="mt-3 ml-3 btn btn-sm btn-outline-primary" v-b-modal.modal-member>
                      View photograph
                      <b-badge variant="primary" class="ml-1"><i class="far fa-eye"></i></b-badge>
                    </button>

                    <b-modal no-close-on-backdrop
                      id="modal-member"
                      title="Member photograph"
                      title-class="font-18"
                      hide-footer
                    >
                      <img :src="server+form.url_photograph" width="100%" />
                    </b-modal>

                    <label for="dropzone" :class="{ 'mb-5': form.url_photograph != '' }">&nbsp;</label>
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      :uploadOnDrop="false"
                      @vdropzone-sending="sendingFile"
                      @vdropzone-complete="uploadCompleted"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Click to search or Drop in here the photograph</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12 mt-5 pt-5">
                  <div class="form-group text-center">
                    <button class="btn btn-success" type="submit">SAVE TEAM</button>
                    <router-link to="/teams" class="ml-3">
                      <button class="btn btn-danger" type="reset">CANCEL </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .vue-input-tag-wrapper {
    height: auto !important;
  }

  .vue-input-tag-wrapper .input-tag {
    background-color: #5664d2 !important;
    border: 1px solid #5664d2 !important;
    color: #fff !important;
  }

  .vue-input-tag-wrapper .input-tag .remove {
    cursor: pointer;
    font-weight: 700;
    color: #fff !important;
  }
</style>